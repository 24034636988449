.single-card-page {
  background-color: #001c3d;
}

.single-card-page .heading {
  font-size: 29px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  color: #191919;
  margin-bottom: 1rem;
}

.single-card-page .logo {
  margin-top: 100px;
  margin-bottom: 20px;
  text-align: center;
}
.single-card-page .logo img {
  width: 50%;
}

.single-card-page form {
  padding: 1rem 0;
}

.single-card-page p {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
}
.single-card-page p a {
  color: #006ff5;
}
