.container ul {
  line-height: 1.8;
  list-style: none;
  padding-left: 1.5rem;
}

.container li {
  position: relative;
}

.container ul li::before {
  background-color: #212529;
  border-radius: 50%;
  content: '';
  height: 5px;
  left: -1rem;
  position: absolute;
  top: 0.72rem;
  width: 5px;
}

.container li ul li::before {
  background-color: transparent;
  border: 1px solid #212529;
}
