html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
  color: #191919;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  line-height: 1.5;
}

.deprecated-styles {
  .text-inherit {
    color: inherit !important;
  }

  .height-max-content {
    height: max-content !important;
  }

  .font-inherit {
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: inherit;
  }

  .fs-12 {
    font-size: 12px;
  }

  & strong {
    font-weight: bold;
  }

  & small {
    font-weight: 200;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .th-font-family-11 > th {
    font-size: 11px;
  }

  /* Grid innerScrollContainer was hiding dropdown menu in some cases */
  .react-virtualized-overflow-visible
    .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .btn.btn-success.disabled,
  .btn.btn.btn-success:disabled {
    opacity: 1;
  }

  .page--full-height {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .page--full-height .page__content {
    flex-grow: 1;
    display: flex;
  }

  :is(h1, h2, h3, .h1, .h2, .h3) {
    font-family: interstate-compressed, sans-serif !important;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
  }

  :is(h4, h5, h6, .h4, .h5, .h6) {
    font-family: acumin-pro-wide, sans-serif !important;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
  }

  .eyebrow {
    font-family: acumin-pro-wide, sans-serif !important;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
  }

  & a[role='button'] {
    cursor: pointer;
  }

  .form-control {
    font-weight: 200;
  }

  .modal-open {
    padding-right: 0px !important; /* fix for page content shifting left when modals open */
    position: fixed; /* prevents body scrolling on iOS when modal is open */
    width: 100%;
  }

  :is(.nav-pills .nav-link.active, .nav-pills .show > .nav-link) {
    background-color: var(--success);
  }

  .prospect-page__sidebar {
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 0px;
    width: 750px;
  }

  .prospect-page__sidebar-and-summary {
    display: flex;
    flex-grow: 1;
    min-height: 0;
  }

  .sidebar {
    width: 450px;
    padding: 1rem;
  }

  .justified-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .summary {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .seasonal-dashboard-sections-sizing {
    width: 266px;
    margin-left: 29px;
  }

  @media only screen and (max-width: 500px) {
    .summary {
      display: none !important;
    }
  }

  .list-group-item-action:hover {
    cursor: pointer;
  }

  .list-group-item-action:focus {
    outline: none !important;
  }

  .map {
    position: relative;
    flex-grow: 1;
    display: flex;
  }

  .leaflet-container {
    z-index: 0;
    flex-grow: 1;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .recenter-button {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }

  #mb-40-important {
    margin-bottom: 40px !important;
  }

  .btn-inline-link {
    border: 0;
    border-radius: 0;
    line-height: inherit;
    margin: 0;
    padding: 0;
    vertical-align: inherit;
  }

  & button.nav-link {
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-weight: 200;
    text-align: left;
    width: stretch;
  }

  .navbar-dark .navbar-nav .nav-link {
    border-bottom: 2px solid transparent;
    color: #fff;
    margin: 0 15px;
    padding: 2px 0 0 0;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: center;
  }

  :is(
      .navbar-dark .navbar-nav .nav-link:hover,
      .navbar-dark .navbar-nav .nav-link:focus,
      .navbar-dark .navbar-nav .show > .nav-link,
      .navbar-dark .navbar-nav .active > .nav-link,
      .navbar-dark .navbar-nav .nav-link.show,
      .navbar-dark .navbar-nav .nav-link.active
    ) {
    border-color: #006ff5;
    color: #fff;
  }

  .navbar-dark .navbar-toggler {
    border: 0;
    color: #fff;
  }

  & input {
    font-weight: 200;
  }

  .dropdown-item:hover {
    cursor: pointer;
  }

  .gavatar {
    background-color: #6e6e6e;
    border-radius: 48px;
    color: rgb(255, 255, 255);
    display: inline-block;
    font-size: 36px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    width: 48px;
    padding-top: 3px;
  }

  .visible-to-sponsor {
    border: 1px solid #401780;
  }

  .ril__caption {
    justify-content: center;
  }

  .DateInput_input {
    font-size: 1rem;
    text-align: center;
  }

  #deadline-input {
    text-align: left;
  }

  .text-disabled {
    opacity: 0.65;
  }

  .text-really-muted {
    opacity: 0.45;
  }

  .bg-lighter {
    background-color: #fafafa !important;
  }

  .compact-line-height {
    line-height: 1.2;
  }

  .border-thick {
    border: 3px solid;
  }

  .height-width-20px {
    height: 20px;
    width: 20px;
  }

  .style-string-with-new-lines {
    white-space: pre-wrap;
  }

  .width-50 {
    width: 50%;
  }

  .width-20 {
    width: 20%;
  }

  /************************ */
  /* Incorporating TeamSnap */
  /************************ */

  .sports-management-software-image {
    width: 46px;
    padding-bottom: 4px;
    margin-left: 5px;
  }

  /************************ */
  /* TeamSnap: Footer       */
  /************************ */

  .footer-text {
    width: 164px;
    height: 24px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    /* Base/Gray 40 */

    color: #191919;
  }

  .footer-legal-button {
    width: 91px;
    height: 16px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    letter-spacing: 0.03em;
    text-decoration-line: underline;

    /* Base/Blue 40 */

    color: #006ff5;
  }

  /************************ */
  /* Modal Spinne           */
  /************************ */

  .modal-spinner-container {
    position: fixed;
    z-index: 999;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #eee;
    background-color: #0008;
    border-radius: 1em;
    padding: 2em;
    visibility: visible;
    opacity: 1;
    width: auto;
    height: auto;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }

  .modal-spinner-container-hidden {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }

  /* Need to consolidate .heading for real headers/headings */
  .modal-spinner-container .heading {
    font-size: 29px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    color: #eee;
    margin-bottom: 1rem;
  }

  /************************ */
  /* TeamSnap: Forms        */
  /************************ */

  .ts-form label {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #191919;
  }

  .ts-form .btn.btn-primary {
    background-color: #006ff5;
    border-color: #006ff5;
  }

  /************************ */
  /* TeamSnap: Buttons      */
  /************************ */

  .btn.btn-link {
    color: #0057be;
  }

  /************************ */
  /* TeamSnap: Loaders      */
  /************************ */

  .JelloAnimation {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 40px;
  }

  .JelloAnimation-box {
    width: 32px;
    height: 32px;
    background: #ff8f00;
    -webkit-animation: jelloBoxAnimation 500ms linear infinite;
    animation: jelloBoxAnimation 500ms linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
  }

  .JelloAnimation-shadow {
    width: 32px;
    height: 4px;
    background: #e6e6e6;
    position: absolute;
    bottom: -4px;
    left: 0;
    border-radius: 50%;
    -webkit-animation: jelloShadowAnimation 500ms linear infinite;
    animation: jelloShadowAnimation 500ms linear infinite;
  }

  .Loader--jello .Loader-indicator {
    height: 48px;
  }

  .Loader--jello .Loader-indicatorText {
    line-height: 40px;
    color: #7a7a7a;
    font-weight: 400;
  }

  .Loader--jello .Loader-message {
    font-size: 23px;
    font-weight: 600;
  }
  .Loader--jello .Loader-message.Loader-message-standard-size {
    font-size: 16px;
    font-weight: normal;
  }
}

@-webkit-keyframes jelloBoxAnimation {
  17% {
    border-bottom-right-radius: 2px;
  }
  25% {
    -webkit-transform: translateY(8px) rotate(22.5deg);
    transform: translateY(8px) rotate(22.5deg);
  }
  50% {
    border-bottom-right-radius: 32px;
    -webkit-transform: translateY(16px) scale(1, 0.9) rotate(45deg);
    transform: translateY(16px) scale(1, 0.9) rotate(45deg);
  }
  75% {
    -webkit-transform: translateY(8px) rotate(67.5deg);
    transform: translateY(8px) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes jelloBoxAnimation {
  17% {
    border-bottom-right-radius: 2px;
  }
  25% {
    -webkit-transform: translateY(8px) rotate(22.5deg);
    transform: translateY(8px) rotate(22.5deg);
  }
  50% {
    border-bottom-right-radius: 32px;
    -webkit-transform: translateY(16px) scale(1, 0.9) rotate(45deg);
    transform: translateY(16px) scale(1, 0.9) rotate(45deg);
  }
  75% {
    -webkit-transform: translateY(8px) rotate(67.5deg);
    transform: translateY(8px) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@-webkit-keyframes jelloShadowAnimation {
  50% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1);
  }
}
@keyframes jelloShadowAnimation {
  50% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1);
  }
}

/************************ */
/* TeamSnap: MISC          */
/************************ */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  font-weight: 300;
}
