.badge {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  font-size: 14px;
  font-weight: 700;
  font-family: var(--font-family-sans-serif);
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1rem;
}

.badge-sm {
  padding: 0.2rem 0.4rem;
  font-size: 11px;
}

.badge:empty {
  display: none;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3b99ff;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #087eff;
}

.badge-secondary {
  color: var(--sui-colors-gray-40);
  background-color: var(--sui-colors-gray-95);
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #c7c7c7;
}

.badge-success {
  color: #fff;
  background-color: #240d4d;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #100621;
}

.badge-info {
  color: #fff;
  background-color: #401780;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #2a0f55;
}

.badge-warning {
  color: #fff;
  background-color: #ffd157;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ffc324;
}

.badge-danger {
  color: #fff;
  background-color: #fa6b4f;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #f9411d;
}

.badge-light {
  color: #212529;
  background-color: #f5f5f5;
}
.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: gainsboro;
}

.badge-dark {
  color: #fff;
  background-color: #333333;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1a1a1a;
}

.badge-prospecting {
  color: #fff;
  background-color: #fd7e14;
  border: 1px solid #fff;
}
.badge-qualified {
  color: #fff;
  background-color: #e83e8c;
  border: 1px solid #fff;
}
.badge-proposed {
  color: #fff;
  background-color: #6f42c1;
  border: 1px solid #fff;
}
.badge-confirmed {
  color: #fff;
  background-color: #007bff;
  border: 1px solid #fff;
}
.badge-zero {
  color: var(--sui-colors-gray-40);
  background-color: var(--sui-colors-gray-95);
}

.badge-average-sponsorship-value {
  color: #fff;
  background-color: #28a745;
  border: 1px solid #fff;
}
.badge-average-sponsorship-value[href]:hover,
.badge-average-sponsorship-value[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #28a745;
  border: 1px solid #fff;
}

.badge-confirmed-sponsorships {
  color: #fff;
  background-color: #3b99ff;
  border: 1px solid #fff;
}
.badge-confirmed-sponsorships[href]:hover,
.badge-confirmed-sponsorships[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3b99ff;
  border: 1px solid #fff;
}

.badge-on-time-verifications {
  color: #fff;
  background-color: #401780;
  border: 1px solid #fff;
}
.badge-on-time-verifications[href]:hover,
.badge-on-time-verifications[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #401780;
  border: 1px solid #fff;
}

.badge-packages {
  color: #fff;
  background-color: #975fff;
  border: 1px solid #fff;
  font-weight: 400;
}
