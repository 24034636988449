.verification-deadline-details-date {
  display: inline-block;
  font-size: 1rem;
}

.verification-deadline-details-header {
  font-family: interstate-compressed, sans-serif !important;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.verification-deadline-details-badge {
  line-height: 1;
}
