.organization-dashboard-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 2rem 0px 15px;
}

.organization-dashboard-sidebar {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow-y: auto;
  min-width: 35%;
  padding: 0px 2rem;
  width: 30rem;
}

.organization-dashboard-sidebar-and-main {
  display: flex;
  flex-grow: 1;
  min-height: 0;
}
