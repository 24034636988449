.map-circle__dot {
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  height: 6px;
  width: 6px;
}

.leaflet-container g {
  opacity: 0.25;
}

path.leaflet-interactive:hover {
  stroke: black;
  stroke-width: 3px;
}
