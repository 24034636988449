.pictureSection {
  max-width: 200px;
}
.picture {
  overflow: hidden;
  border-radius: 8px;
  max-height: 170px;
}

@media (min-width: 768px) {
  .pictureSection {
    max-width: 280px;
  }
  .pictureSection-lg {
    max-width: 420px;
  }
  .picture {
    max-height: inherit;
  }
}

.fixedStylesForSnapUI {
  label {
    font-size: 14px;
    margin-bottom: 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='date']::placeholder {
    background-color: red !important;
    color: blue !important;
  }
  :global(.sui-react-select__placeholder) {
    font-size: 13px;
    color: var(--sui-colors-gray-60);
  }
  :global(.sui-react-select__control) {
    font-size: 13px;
  }
  :global(.sui-react-select__multi-value__label) {
    font-size: 13px;
  }
}

.fieldset {
  display: flex;
  flex-direction: column;
  label {
    font-size: 14px;
    margin-bottom: 8px;
  }
}
.withIcon {
  position: relative;
  input {
    padding-right: 2.5rem;
  }
}
.icon {
  position: absolute;
  right: 1rem;
  top: 36px;
  color: var(--sui-colors-gray-60);
}

.videoWrapper {
  margin-bottom: 2rem;
  height: calc((100vw - 4rem) * 0.564);

  @media (min-width: 1024px) {
    margin-bottom: 3rem;
    height: calc((100vw - 6rem - 239px) * 0.564);
    max-height: 621px;
  }
}

.progressBar {
  height: 4px;
  background-color: var(--sui-colors-gray-95);
  width: 100%;
  border-radius: 12px;
  position: relative;

  &::-webkit-progress-bar {
    height: 4px;
    background-color: var(--sui-colors-gray-95);
    border-radius: 8px;
  }
  &::-webkit-progress-value {
    background-color: var(--sui-colors-green-40);
    border-radius: 8px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 4px;
    width: 4px;
    background-color: var(--sui-colors-gray-50);
    border-radius: 100%;
  }
}

.wizardHeader {
  height: 93px;
}

.wizardStepBody {
  min-height: calc(100svh - 227px);
  @media (min-width: 1024px) {
    min-height: calc(100svh - 191px);
  }
}
