.campaign-dashboard-map {
  height: 75vh;
  width: 100%;
}

.campaign-dashboard-map__icon {
  align-items: center;
  border: 2px solid #3b99ff;
  border-color: var(--primary);
  border-radius: 50%;
  color: var(--primary);
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;
}

.campaign-dashboard-map__icon::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -19px;
  left: 3px;
  border: 7px solid transparent;
  border-top: 12px solid #3b99ff;
}
