.leaflet-div-icon {
  background: none;
  border: none;
}

.map__cluster-icon {
  background: var(--gray);
  border: 0;
  border-radius: 50%;
  color: white;
  line-height: 30px;
  opacity: 0.75 !important;
  text-align: center;
}

.map__sponsorable_property-icon {
  align-items: center;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;
}

.map__sponsorable_property-icon::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -19px;
  left: 3px;
  border: 7px solid transparent;
  border-top: 12px solid #000;
}

.map__sponsorable_property-icon--added {
  border-color: var(--success);
  color: var(--success);
}

.map__sponsorable_property-icon--added::after {
  border-top-color: var(--success);
}

.map__sponsorable_property-icon--removed {
  border-color: var(--danger);
  color: var(--danger);
}

.map__sponsorable_property-icon--removed::after {
  border-top-color: var(--danger);
}

.map__sponsorable_property-icon--hover {
  border-color: var(--warning);
  color: var(--warning);
}

.map__sponsorable_property-icon--hover::after {
  border-top-color: var(--warning);
}

.map__sponsorable_property-icon--focus {
  border-color: var(--primary);
  color: var(--primary);
}

.map__sponsorable_property-icon--focus::after {
  border-top-color: var(--primary);
}
